import Link from 'next/link';

const NavigationLists = ({ routes, titleClassNames, linkClassNames }) => (
  <ul className="md:flex md:flex-row">
    {routes.map((page) => (
      <li key={page.title} className="pb-14 md:w-1/2">
        <h4 className={`text-base uppercase text-xl pb-2 ${titleClassNames}`}>
          {page.title}
        </h4>
        <ul>
          {page.routes.map((route) => (
            <li key={route.title}>
              {route.isExternal ? (
                <a
                  className={`text-base leading-8 ${linkClassNames}`}
                  href={route.route}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {route.title}
                </a>
              ) : (
                <Link href={route.route}>
                  <a className={`text-base leading-8 ${linkClassNames}`}>
                    {route.title}
                  </a>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
);

export default NavigationLists;
