const MakaraByBetterment = () => (
<svg width="166" height="40" viewBox="0 0 166 40" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_27_12)">
<path d="M41.298 0.00134277H39.5195L32.7108 16.5551H34.9567L36.8989 11.7065H41.7633L41.1366 10.0741H37.3201L40.4083 2.42675L43.4037 10.0741H43.3915L45.8809 16.5551H48.0837L41.298 0.00134277Z" fill="#030319"/>
<path d="M70.4458 10.2349L75.1898 16.5561H77.4835L71.6749 8.92084L70.4458 10.2349Z" fill="#030319"/>
<path d="M74.8428 2.47956L77.1075 0.000183105H74.8394L66.2752 9.16536V0.0255165H64.1696V16.5561H66.2752V11.496L69.1525 8.51221L69.1646 8.52764L74.8296 2.46633L74.8428 2.47956Z" fill="#030319"/>
<path d="M100.12 0.00134277H98.3411L91.5321 16.5551H93.7772L95.7202 11.7065H100.602L99.9747 10.0741H96.1414L99.2297 2.42675L101.68 8.6797L101.692 8.67419L104.72 16.5551H106.904L100.12 0.00134277Z" fill="#030319"/>
<path d="M133.086 9.53771C133.515 9.2292 133.889 8.8513 134.192 8.41976C134.5 7.97292 134.737 7.48091 134.894 6.96143C135.058 6.42083 135.141 5.85873 135.138 5.29383C135.137 4.63494 135.01 3.98224 134.764 3.37068C134.519 2.74779 134.167 2.17203 133.724 1.66894C133.279 1.16421 132.738 0.752401 132.132 0.457344C131.501 0.149515 130.806 -0.00701424 130.103 0.00024119H122.99V16.5562H125.09V1.8661H129.979C130.381 1.86535 130.778 1.95734 131.139 2.13485C131.508 2.31391 131.835 2.56835 132.098 2.88164C132.376 3.20805 132.597 3.578 132.754 3.97648C132.919 4.3976 133.002 4.84613 132.999 5.29823C133 5.74213 132.93 6.1833 132.789 6.60456C132.658 7.00221 132.46 7.37471 132.203 7.706C131.961 8.02048 131.654 8.2793 131.302 8.46491C130.94 8.65367 130.535 8.74979 130.126 8.74468H127.203V10.6105H129.495L133.285 16.5584H135.672L131.647 10.2162C132.164 10.0817 132.651 9.85232 133.084 9.53993" fill="#030319"/>
<path d="M155.08 10.0741L158.169 2.42675L161.164 10.0741H161.14L163.603 16.5562H165.843L159.059 0.00134277H157.279L150.471 16.5562H152.716L154.659 11.7065H159.508L158.888 10.0741H155.08Z" fill="#030319"/>
<path d="M0.299995 0.00134277L6.29753 10.7207L7.33766 8.86914L2.3747 0.00134277H0.299995Z" fill="#030319"/>
<path d="M7.80303 13.5711H8.99789L12.4266 7.47345V3.72742L7.34541 12.7604L7.80303 13.5711Z" fill="#030319"/>
<path d="M0.167361 16.5263H0.151886V16.556H2.25867V7.42941L0.167361 3.72742V16.5263Z" fill="#030319"/>
<path d="M14.5335 16.5265H14.5202V16.5562H16.6259V0.00134277H14.5335V16.5265Z" fill="#030319"/>
<path d="M121.541 31.5553C121.122 31.5498 120.707 31.6513 120.335 31.851C119.962 32.0505 119.643 32.3421 119.405 32.7001V31.7499H117.75V38.1948H119.405V35.0404C119.405 33.8282 119.96 33.1049 120.89 33.1049C121.709 33.1049 122.295 33.6678 122.295 34.6088V38.1948H123.938V34.2483C123.938 32.5649 122.958 31.5553 121.541 31.5553Z" fill="#030319"/>
<path d="M105.189 38.1903H103.444V34.6044C103.444 33.7189 102.892 33.1008 102.1 33.1008C101.111 33.1008 100.521 33.824 100.521 35.036V38.1903H98.7623V31.7479H100.521V32.7001C100.774 32.3422 101.113 32.0506 101.509 31.8509C101.905 31.6512 102.345 31.5496 102.791 31.5553C103.799 31.5553 104.561 32.0617 104.934 32.9155C105.199 32.4956 105.57 32.1496 106.012 31.9111C106.455 31.6727 106.953 31.5503 107.458 31.5553C108.915 31.5553 109.856 32.6118 109.856 34.2487V38.1949H108.112V34.609C108.112 33.7235 107.56 33.1054 106.767 33.1054C105.779 33.1054 105.188 33.8286 105.188 35.0407L105.189 38.1903Z" fill="#030319"/>
<path d="M69.3486 33.851L68.9729 33.6999L69.3319 33.5157C69.3742 33.4943 70.3354 32.9781 70.3354 31.7365C70.3354 30.6884 69.4073 29.6273 67.6332 29.6273H63.3461V38.1948H67.6415C70.4577 38.1948 70.5998 36.2537 70.5998 35.8648C70.6007 34.3731 69.361 33.8571 69.3486 33.851ZM65.1195 31.037H67.2388C67.8203 31.037 68.4466 31.3777 68.4466 32.1269C68.4466 32.8579 67.8389 33.2408 67.2388 33.2408H65.1195V31.037ZM67.5164 36.7852H65.1195V34.5814H67.5164C68.3016 34.5814 68.7119 35.1417 68.7119 35.6961C68.7119 36.3282 68.209 36.7865 67.5164 36.7865V36.7852Z" fill="#030319"/>
<path d="M77.8538 34.9843C77.8157 32.9659 76.4324 31.5551 74.4886 31.5551C72.5154 31.5551 71.0271 33.0287 71.0271 34.9831C71.0153 35.8775 71.3534 36.7417 71.9705 37.395C72.6015 38.05 73.5062 38.409 74.49 38.409C75.4739 38.409 76.3784 38.0487 77.0093 37.395C77.3485 37.04 77.6047 36.6156 77.7604 36.1518H76.1662C75.8398 36.7272 75.2246 37.076 74.49 37.076C73.6439 37.076 72.9613 36.4804 72.7735 35.5589L72.7277 35.336H77.8538V34.9843ZM72.8213 34.1604C72.9228 33.8008 73.1411 33.484 73.4425 33.2594C73.7438 33.0348 74.1114 32.9145 74.4886 32.9172C75.284 32.9172 75.8787 33.3823 76.0862 34.1604H72.8213Z" fill="#030319"/>
<path d="M93.6418 34.9843C93.6047 32.9659 92.2648 31.5551 90.3821 31.5551C88.47 31.5551 87.0282 33.0287 87.0282 34.9831C87.0173 35.8773 87.3447 36.7417 87.9423 37.395C88.5534 38.05 89.4296 38.409 90.3833 38.409C91.3371 38.409 92.2127 38.0487 92.8237 37.395C93.1522 37.04 93.4004 36.6156 93.5512 36.1518H92.005C91.6889 36.7272 91.0923 37.076 90.3815 37.076C89.5619 37.076 88.9006 36.4804 88.7187 35.5589L88.6744 35.336H93.6399L93.6418 34.9843ZM88.7662 34.1604C88.8648 33.8006 89.0765 33.484 89.3685 33.2594C89.6603 33.0348 90.0166 32.9145 90.3821 32.9172C91.1524 32.9172 91.7285 33.3823 91.9297 34.1604H88.7662Z" fill="#030319"/>
<path d="M116.897 34.9843C116.86 32.9659 115.52 31.5551 113.637 31.5551C111.724 31.5551 110.283 33.0287 110.283 34.9831C110.272 35.8773 110.599 36.7415 111.197 37.395C111.808 38.05 112.685 38.409 113.638 38.409C114.591 38.409 115.467 38.0487 116.079 37.395C116.407 37.04 116.655 36.6156 116.806 36.1518H115.26C114.944 36.7272 114.348 37.076 113.636 37.076C112.816 37.076 112.155 36.4804 111.973 35.5589L111.929 35.336H116.897V34.9843ZM112.021 34.1604C112.12 33.8006 112.331 33.484 112.623 33.2592C112.915 33.0346 113.271 32.9145 113.637 32.9172C114.407 32.9172 114.983 33.3823 115.184 34.1604H112.021Z" fill="#030319"/>
<path d="M98.3355 31.5614C97.2503 31.4942 96.3275 31.9656 95.8605 32.8586V31.7493H94.0684V38.1949H95.8605V35.3965C95.8605 33.6871 97.0843 32.8148 98.3355 33.3045V31.5614Z" fill="#030319"/>
<path d="M86.8251 36.7932C86.5729 36.9044 86.3003 36.9619 86.0248 36.9626C85.2635 36.9626 84.8438 36.4989 84.8438 35.6565V33.0751H86.9005V31.7582H84.8425V28.9856L83.126 29.6112V31.7582H80.2105V28.9849L78.4941 29.6106V35.8874C78.4941 37.537 79.3476 38.409 80.9615 38.409C81.5914 38.409 82.1317 38.3071 82.6081 38.0971L82.1918 36.7947C81.9395 36.9056 81.667 36.9634 81.3915 36.964C80.6302 36.964 80.2105 36.5003 80.2105 35.658V33.0769H83.1275V35.8874C83.1275 37.537 83.9809 38.409 85.5957 38.409C86.2248 38.409 86.765 38.3071 87.2415 38.0971L86.8251 36.7932Z" fill="#030319"/>
<path d="M128.434 36.7926C128.186 36.9035 127.918 36.9613 127.646 36.9619C126.896 36.9619 126.483 36.4983 126.483 35.6559V33.0742H128.508V31.7575H126.482V28.9849L124.791 29.6104V35.8874C124.791 37.537 125.632 38.409 127.223 38.409C127.843 38.409 128.376 38.3071 128.844 38.0971L128.434 36.7926Z" fill="#030319"/>
<path d="M54.5129 37.1049L59.867 39.0517C60.7439 37.9169 61.2544 36.4723 61.2544 34.8608C61.2544 31.0227 58.3566 28.1281 54.5129 28.1281C50.6694 28.1281 47.7716 31.0227 47.7716 34.8608C47.7716 36.4723 48.2828 37.915 49.1597 39.0517L54.5129 37.1049Z" fill="#FFC729"/>
<path d="M33.9463 37.6529C33.4001 37.6529 32.9318 37.5537 32.5419 37.355C32.1588 37.1493 31.8678 36.8727 31.6694 36.525L31.531 37.4934H30.7648V29.7791H31.7012V33.1734C31.8998 32.8186 32.2013 32.5385 32.6057 32.3328C33.017 32.1199 33.4818 32.0135 33.9994 32.0135C34.5387 32.0135 35.0139 32.1341 35.4254 32.3752C35.8367 32.6093 36.1524 32.9392 36.3724 33.3648C36.5993 33.7905 36.7128 34.28 36.7128 34.8332C36.7128 35.3794 36.5958 35.869 36.3617 36.3017C36.1277 36.7273 35.8014 37.0607 35.3827 37.3019C34.9642 37.5359 34.4855 37.6529 33.9463 37.6529ZM33.6804 36.8336C34.3046 36.8336 34.8012 36.6527 35.17 36.291C35.5389 35.9293 35.7233 35.4432 35.7233 34.8332C35.7233 34.2161 35.546 33.7302 35.1912 33.3754C34.8437 33.0137 34.3543 32.8328 33.7229 32.8328C33.1058 32.8328 32.6093 33.003 32.2333 33.3436C31.8643 33.684 31.6798 34.138 31.6798 34.7055V34.9716C31.6798 35.532 31.8609 35.9824 32.2226 36.3228C32.5915 36.6634 33.0773 36.8336 33.6804 36.8336ZM38.0377 39.6533C37.839 39.6533 37.6546 39.6393 37.4842 39.6108V38.7808C37.6546 38.8021 37.807 38.8128 37.9418 38.8128C38.3533 38.8128 38.6901 38.7241 38.9526 38.5468C39.2223 38.3695 39.428 38.1034 39.5698 37.7488L39.6868 37.4614L37.0586 32.173H38.144L39.9849 36.0781C40.0558 36.2554 40.1126 36.4223 40.155 36.5782H40.1657C40.2507 36.3157 40.3076 36.1491 40.3359 36.0781L42.0598 32.173H43.1024L40.5061 37.8338C40.2082 38.4865 39.8607 38.9512 39.4634 39.2277C39.0732 39.5116 38.598 39.6533 38.0377 39.6533Z" fill="#030319"/>
</g>
<defs>
<clipPath id="clip0_27_12">
<rect width="166" height="39.773" fill="white"/>
</clipPath>
</defs>
</svg>
);

export default MakaraByBetterment;
