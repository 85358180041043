const Address = ({ className }) => (
  <address className={`text-xs not-italic ${className}`}>
    <p className="pb-8">
      &copy; 2022 Argonaut Asset Management LLC
      <br />
      dba Makara Digital Corporation (“Makara”).
      <br />
      All rights reserved.
    </p>

    <p>
      8 W. 24th Street, 6th Floor
      <br />
      New York, NY 10010
    </p>
  </address>
);

export default Address;
