import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

export default class MetaOgHead extends React.Component {
  render() {
    const { title, desc, url, imageUrl, author } = this.props;

    function siteUrl() {
      if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
        return process.env.NEXT_PUBLIC_APP_URL;
      }

      return `https://${
        process.env.NEXT_PUBLIC_VERCEL_URL || 'localhost:3000'
      }`;
    }

    const image = imageUrl.startsWith('http') ? imageUrl : siteUrl() + imageUrl;

    return (
      <Head>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={desc} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl() + url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={image} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={image} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={desc} />

        {author && <meta property="article:author" content={author} />}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
    );
  }
}

MetaOgHead.propTypes = {
  desc: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  author: PropTypes.string,
};
