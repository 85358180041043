import Head from 'next/head';
import Link from 'next/link';

import MakaraByBetterment from '../components/svgs/MakaraByBetterment';
import Footer from './Footer';

const Main = ({ className = 'bg-[#FDFCD0]', children }) => (
  <>
    <Head>
      <script src="https://www.googleoptimize.com/optimize.js?id=OPT-PHBVD4W" />
      {/* Google Optimize */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png?v=2"
      />
      <link rel="shortcut icon" href="/favicon.ico?v=2" />
    </Head>

    <header className="max-w-global w-full lg:h-28 lg:flex lg:items-center bg-[#FDFCD0]">
      <div className="flex flex-row items-center justify-between w-full py-5 px-7">
        <Link href="/">
          <a className="transition-colors duration-300 ease-in-out text-black-400">
            <MakaraByBetterment />
          </a>
        </Link>
      </div>
    </header>

    <main className={className}>{children}</main>

    <Footer />
  </>
);

export default Main;
