import Link from 'next/link';

import MakaraByBetterment from '../components/svgs/MakaraByBetterment';

import Address from './Address';
import NavigationLists from './NavigationLists';

const routes = [
  {
    title: 'Legal',
    routes: [
      {
        isExternal: true,
        title: 'SEC Form CRS',
        route: 'https://reports.adviserinfo.sec.gov/crs/crs_312664.pdf',
      },
      {
        isExternal: true,
        title: 'SEC Form ADV Part II',
        route:
          'https://files.adviserinfo.sec.gov/IAPD/Content/Common/crd_iapd_Brochure.aspx?BRCHR_VRSN_ID=780602',
      },
      {
        title: 'Advisory Agreement',
        route: '/legal/advisory-agreement',
      },
      {
        title: 'Terms of Service',
        route: '/legal/terms',
      },
      {
        title: 'Privacy Policy',
        route: '/legal/privacy',
      },
    ],
  },
];

export default function Footer() {
  return (
    <footer
      className="relative z-0 px-8 overflow-x-hidden bg-gray-100 shadow-inner py-14"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>

      <h3 className="mb-14">
        <Link href="/">
          <a className="text-black-400">
            <MakaraByBetterment />
          </a>
        </Link>
      </h3>

      <div className="lg:flex lg:flex-row lg:items-start">
        <div className="lg:order-1 lg:w-2/3">
          <NavigationLists
            routes={routes}
            titleClassNames="text-black-500"
            linkClassNames="text-navy-400"
          />
        </div>

        <div className="lg:flex-grow-0 lg:order-2 lg:w-1/3">
          <Address className="text-navy-300" />
        </div>
      </div>
    </footer>
  );
}
